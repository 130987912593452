import styled from '@emotion/styled'

export const CheckoutLink = styled.a`
    background-color: #338FCC;
    padding: 1rem 3rem 0.8rem;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    line-height: 1;
    font-size: 1.5rem;

    ${props => props.disabled && `
        background-color: grey;
        cursor: not-allowed;
    `}
`