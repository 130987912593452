import React, { useContext } from 'react'

import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import { formatPrice } from '../../utils'
import { CheckoutLink } from './styles'


const Cart = () => {
  const {
    store: { checkout },
    
  } = useContext(StoreContext)

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  const cartIsEmpty = checkout.lineItems.length === 0;

  return (
    <div>
      {line_items}
      <h2>Subtotal</h2>
      <p>{formatPrice(checkout.subtotalPrice, checkout.currencyCode)}</p>
      <br />
      <h2>Taxes</h2>
      <p>{formatPrice(checkout.totalTax, checkout.currencyCode)}</p>
      <br />
      <h2>Total</h2>
      <p>{formatPrice(checkout.totalPrice, checkout.currencyCode)}</p>
      <br />
      <CheckoutLink href={!cartIsEmpty ? checkout.webUrl : null} disabled={cartIsEmpty}>Check out</CheckoutLink>
    </div>
  )
}

export default Cart
